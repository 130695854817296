<template>
  <div class="col-lg-3">
    <side-nav
      :items="navigationItems"
    />
  </div>

  <div class="col-lg-9 mt-lg-0 mt-4">
    <news-item-summary
      v-model:visual-image="fields.visualImage"
      v-model:visual-image-data="fields.visualImageData"
      v-model:author-user-id="fields.createdByUserId"
      :created-date-time="fields.createDate"
      :title="fields.title"
      :enable-author-selection="isNewItem"

    />

    <form @submit.prevent="submit">
      <base-fieldset
        id="basic-info"
        title="Algemeen"
      >
        <basic-info
          v-model:description="fields.description"
          v-model:title="fields.title"
          v-model:url="fields.url"
          v-model:publishDateTime="fields.publishDate"
          :show-update="!isNewItem"
        />
      </base-fieldset>

      <div class="card mt-4">
        <div class="card-body">
          <file-upload
            v-model:files="files"
            attachment-type="0"
            :entity-id="entityId"
            @update:files="files => updateFiles(files)"
          />
          </div>
      </div>

      <div class="card mt-4">
        <div class="card-body">
          <div class="d-flex align-items-center mb-sm-0 mb-4">
            <div class="w-50">
              <h5>Opslaan</h5>
            </div>
            <div class="w-50 text-end">
              <material-button
                :color="submitButtonColor"
                variant="gradient"
                class="float-end mt-4 mb-0 mx-4"
              >
                {{ submitButtonText }}
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!isNewItem"
        id="delete"
        class="card mt-4"
      >
        <div class="card-body">
          <div class="d-flex align-items-center mb-sm-0 mb-4">
            <div class="w-50">
              <h5>Verwijder dit nieuwsbericht</h5>
              <p class="text-sm mb-0">
                Wanneer een nieuwsbericht verwijderd wordt, kan dit niet meer ongedaan gemaakt worden.
              </p>
            </div>
            <div class="w-50 text-end">
              <material-button
                color="danger"
                variant="gradient"
                class="mb-0 ms-2"
                @click.prevent="remove"
              >
                Delete Bericht
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { add, edit, get, remove } from '@/api/providers/news';
import { add as  addFile, get as  getFiles, edit as editFile } from "@/api/providers/attachment";

import BaseFieldset from '@/components/Forms/BaseFieldset.vue';
import BasicInfo from '@/components/News/Edit/BasicInfo.vue';
import NewsItemSummary from '../../components/News/Edit/NewsItemSummary.vue';
import MaterialButton from '@/components/MaterialButton.vue';
import SideNav from '@/components/UI/SideNav.vue';

import FileUpload from '../../components/UI/FileUpload.vue';

export default {
  name: 'NewsItemEdit',

  components: {
    BaseFieldset,
    BasicInfo,
    NewsItemSummary,
    MaterialButton,
    SideNav,
    FileUpload
  },

  data: () => ({
    fields: {
      title: '',
      description: '',
      publishDate: '',
      visualImageData: '',
      url: '',
      organizationId: 0,
      createdByUserId: 0
    },
    files: [],
    loading: false,
  }),

  computed: {
    ...mapGetters('account', [
      'organizationId',
    ]),

    entityId() {
      return this.$route.params?.id ?? 0
    },

    isNewItem() {
      return this.$route.params?.id === undefined;
    },

    navigationItems() {
      return [
        {
          href: '#summary',
          icon: 'image',
          name: 'Samenvatting',
        },
        {
          href: '#basic-info',
          icon: 'notes',
          name: 'Algemeen',
        }
      ];
    },

    submitData() {
      return {
        ...this.fields,
        organizationId: this.organizationId,

        defaultAuthorId: this.fields.createdByUserId,
      };
    },

    submitButtonColor() {
      return this.isNewItem ? 'primary' : 'dark'
    },

    submitButtonText() {
      return this.isNewItem ? 'Opslaan' : 'Update gegevens'
    }

  },

  mounted() {
    if (this.isNewItem) {
        return;
    }

    this.loadNewsItemData();
    this.loadNewsItemAttachments();
  },

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    editFiles()
    {
      const files = this.files ?? []
      return new Promise(function (resolve, reject) {
        // Loop over all files that have not been uploaded yet.
        files.filter(f => f.id !== 0).forEach(file => {
            editFile(file.id, file.title)
              .catch(() => reject())
        })

        resolve();
      })
    },

    uploadNewFiles(entityId) {
      const files = this.files ?? [];

      return new Promise(function (resolve, reject) {
        files.filter(f => f.id === 0 && f.data !== undefined).forEach(file => {
            addFile(0, entityId, file.title, file.data)
              .catch(() => reject())
        })

        resolve();
      })
    },

    addNewsItem()
    {
      add(this.submitData)
        .then(createdEntity => this.uploadNewFiles(createdEntity.id)
          .then(this.handleSaveSuccess)
        )
        .catch(error => { if(error.default !== undefined) error.default() })
        .then(() => this.loading = false)
    },

    editNewsItem() {
      edit(this.submitData)
        .then(this.uploadNewFiles(this.$route.params?.id))
          .catch(error => { if(error.default !== undefined) error.default() })
          
        .then(this.editFiles)
          .then(this.handleSaveSuccess)
          .catch(error => { if(error.default !== undefined) error.default() })

        .catch((error) => error.default())
        .then(() => {
          this.loading = false;
        });
    },

    handleGetSuccess(data) {
      Object.entries(data).forEach(([key, value]) => {
        this.fields[key] = value;
      });
    },

    handleGetFilesSuccess(data) {
      this.updateFiles(data)
    },

    handleRemoveSuccess() {
      this.addNotification({
        description: `${this.fields.title} is verwijderd.`,
        icon: {
          component: 'event',
        },
      });

      this.navigateToNewsItemOverview();
    },

    handleSaveSuccess() {
      this.addNotification({
        description: `${this.fields.title} is opgeslagen.`,
        icon: {
          component: 'news',
        },
      });

      this.navigateToNewsItemOverview();
    },

    loadNewsItemData() {
      get(this.$route.params.id)
        .then(this.handleGetSuccess)
        .catch((error) => error.default());
    },

    loadNewsItemAttachments()
    {
      getFiles(0, this.$route.params?.id)
        .then(this.handleGetFilesSuccess)
        .catch((error) => error.default())
    },

    navigateToNewsItemOverview() {
      this.$router.push({
        name: 'newsitem',
      });
    },

    remove() {
      if (!window.confirm(`Nieuwsbericht ${this.fields.title} verwijderen?`)) {
        return;
      }

      remove(this.$route.params.id)
        .then(this.handleRemoveSuccess)
        .catch((error) => error.default());
    },

    submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      if (this.isNewItem) {
        this.addNewsItem()
      } else {
        this.editNewsItem();
      }
    },

    updateFiles(files) { this.files = files }
  },
};
</script>
